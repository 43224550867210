import dayjs from 'dayjs';

import { ENDPOINTS } from 'other/config';
import { GA } from 'services/analytics';
import { http } from 'services/http';
import { SettingsService } from 'services/settings';

import { EMapLayer, EUserAuthority, TLayerInfo } from 'types';
import { EMapOptionsActions, fetchInfoSet } from './mapOptionsConstants';
import { TAction } from '../../_utils/reducerCreator';
import { TMapOptionsState } from './mapOptionsModel';
import { TState } from '../../appStateModel';

/**
 *
 * @param options
 */
export function updateMapOptionsAction(options: Partial<TMapOptionsState>) {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    const update = {
      ...mapOptions,
      ...options
    };
    const action: TAction<TMapOptionsState, EMapOptionsActions> = {
      type: EMapOptionsActions.MAP_SET_OPTIONS,
      payload: update
    };

    SettingsService.writeSettings({ [SettingsService.MAP_SETTINGS]: update });
    dispatch(action);

    if ('layer' in options) {
      GA.reportLayerChange(options.layer);
    }
  };
}

/**
 *
 */
export function checkMapOptions() {
  return (dispatch, getState) => {
    const { mapOptions, session } = getState() as TState;
    const permissions = session.user?.userInfo?.authorities || [];

    if (
      !permissions.includes(EUserAuthority.VIEW_MAP_ICE_LAYER) &&
      mapOptions.layer === EMapLayer.ICE
    ) {
      dispatch(updateMapOptionsAction({ layer: EMapLayer.EEZ }));
    }
  };
}

/**
 *
 */
export function fetchLayerInfoAction() {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    if (Object.keys(mapOptions.layerInfo).length > 0 || mapOptions.isPending) {
      return;
    }

    dispatch(fetchInfoSet.request());

    http
      .send(ENDPOINTS.LAYER_INFO)
      .then((r: Record<number, TLayerInfoSimple>) => {
        const layerInfo = handleInfo(r);

        const label =
          Array.isArray(layerInfo[mapOptions.layer].dateList) &&
          layerInfo[mapOptions.layer].dateList.length > 0
            ? layerInfo[mapOptions.layer].dateList[0].label
            : void 0;

        dispatch(
          fetchInfoSet.success({
            layerDateLabel: label,
            layerInfo: layerInfo
          })
        );
      })
      .catch((e) => dispatch(fetchInfoSet.error(e)));
  };
}

/**/
type TLayerInfoSimple = Omit<TLayerInfo, 'dateList'> & {
  dateList: string[];
};

/**
 *
 */
function handleInfo(
  info: Record<number, TLayerInfoSimple>
): Record<EMapLayer, TLayerInfo> {
  const data = {};
  Object.values(info).forEach((v: TLayerInfoSimple) => v && (data[v.name] = v));

  return {
    [EMapLayer.ICE_COVERAGE]: processDates(data['IceCoverageSmooth']),
    [EMapLayer.PHYTOPLANKTON]: processDates(data['PlanktonSmooth']),
    [EMapLayer.SALINITY]: processDates(data['SalinitySmooth']),
    [EMapLayer.SEATEMP]: processDates(data['SeaTemperatureSmooth']),
    [EMapLayer.SURFACETEMP]: processDates(data['SeaTemperatureSmooth']),
    [EMapLayer.UPWELLING]: processDates(data['UpwellingSmooth']),
    [EMapLayer.ZOOPLANKTON]: processDates(data['ZooplanktonSmooth'])
  } as any;
}

/**
 *
 */
function processDates(info: TLayerInfoSimple): TLayerInfo {
  if (!info) return {} as any;

  return {
    ...info,
    dateList: info.dateList
      .filter((d: string) => dayjs(d).isAfter(dayjs()) || dayjs(d).isToday())
      .slice(0, 5)
      .map((date: string, idx: number, arr: string[]) => ({
        dateTime: date,
        label: convertToDateLabel(date, idx, arr)
      }))
  };
}

/**
 *
 */
function convertToDateLabel(date: string, idx: number, arr: string[]): string {
  if (idx === 0) return dayjs(date).format('MMM DD');

  // Current date is of same date as previous;
  const isSameDate =
    dayjs(date).get('date') === dayjs(arr[idx - 1]).get('date');

  return isSameDate
    ? dayjs(date).clone().add(1, 'day').format('MMM DD')
    : dayjs(date).format('MMM DD');
}
